import React from 'react';
import './App.scss';
import FooterComponent from './components/footer/footer.component';
import HeaderComponent from './components/header/header.component';
import IntroductionComponent from './components/introduction/introduction.component';

function App() {
  return (
    <div className="container-fluid">
    <HeaderComponent/>
    <IntroductionComponent/>
    <FooterComponent/>
    </div>
  );
}

export default App;
