import "./footer.component.scss";
import MailIcon from "@mui/icons-material/Mail";

const FooterComponent = () => {
    return <div className="row footer-container">
        <div className="col-10 me-auto ms-auto">
            <div className="tag-line mt-5 mb-4">
            » WE MAKE PLATFORM PLAYERS SUCCESSFUL! «
            </div>
            <div className="copyright mb-4">
                <MailIcon/> support@neo-prisma.com
            </div>
            <div className="copyright mb-5">
                &copy; 2024 Neo Prisma
            </div>
        </div>
    </div>
}

export default FooterComponent;