import "./header.component.scss";

const HeaderComponent = () => {
   
    return <div className="row header-container">
        <div className="col-10 me-auto ms-auto">
            <div className="greetings">
                HI THERE!
            </div>
            <div className="subheading">
            WE ARE LEADING THE REVOLUTION FOR DTC AND MARKETPLACE SAAS. BOOST YOUR CUSTOMER BASE AND LAUNCH YOUR OWN MARKETPLACE!
            </div>
            <div className="brand-container">
                Neo Prisma
            </div>
        </div>
    </div>
}
export default HeaderComponent;