import "./introduction.component.scss";

const IntroductionComponent = () => {
    return <div className="row introduction-container">
        <div className="col-10 me-auto ms-auto">
            <div className="heading mt-5">
                develop your platform
            </div>
            <div className="heading">
                strategy
                <hr className="introduction-hr"/>
            </div>
            <div className="subheading mt-4">
            NETWORK WITH THE DIGITAL RETAIL WORLD
            </div>

            <div className="text mt-3 mb-5">
            The transformation is in full swing. Don’t get left behind and develop your own platform strategy! As the leading fashion and lifestyle integrator in Europe, we’ve definitely got you covered. Whether you are a brand or a retailer, we enable seamless connections with digital retail. Thanks to our strong network of brands and retailers, paired with first-class software and services, your ambitions are translated directly into success at the push of a button.
            </div>
        </div>
    </div>
}

export default IntroductionComponent;